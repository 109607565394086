import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { postCrops } from "../services/api/crops";
import SweetAlert from "./shared/SweetAlert";
import Loading from "./shared/Loading";

export default function NewCrop() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [selectedFile, setSelectedFile] = useState('')
  const [dialogInfo, setDialogInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    if (!name || !description) {
      //alert("Please correct the form before continuing")
      setDialogInfo({
        title: "Failed",
        text: "Please correct the form before continuing.",
        icon: "error",
        confirmButtonText: "Close",
      });
      return
    }
    setIsLoading(true);
    const data = new FormData() 
    data.append('thumbnail', selectedFile)
    data.append('key', name.replace(' ', '-')?.trim()?.toLowerCase() || name)
    data.append('name', name)
    data.append('description', description)
    const { success, message } = await postCrops(data)
    setIsLoading(false);
    if (!success) {
      //alert("Saving this Crop failed. Kindly refresh and try again.")
      setDialogInfo({
        title: "Failed",
        text: "Saving this crop failed. Kindly refresh and try again.",
        icon: "error",
        confirmButtonText: "Close",
      });
      return
    }

    //alert(message)
    setDialogInfo({
      title: "Success",
      text: message,
      icon: "success",
      confirmButtonText: "Close",
    });
    setOpen(false);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  return (
    <>
      {dialogInfo && (
        <SweetAlert
          title={dialogInfo.title}
          text={dialogInfo.text}
          icon={dialogInfo.icon}
          confirmButtonText={dialogInfo.confirmButtonText}
        />
      )}
      <div>
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          onClick={() => setOpen(true)}
        >
          Create Crop
        </button>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      onSubmit={handleFormSubmit}
                      action="POST"
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                    >
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                {" "}
                                New crop{" "}
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Create a new crop.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Divider container */}
                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                          {/* crop name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="crop-name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                {" "}
                                Crop name{" "}
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="crop-name"
                                id="crop-name"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                          {/* Crop description */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="crop-description"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                {" "}
                                Description{" "}
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <textarea
                                type="text"
                                name="crop-description"
                                id="crop-description"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="thumbnail"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Thumbnail
                              </label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                  type="file"
                                  name="thumbnail"
                                  id="thumbnail"
                                  className="px-2 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  onChange={(e) =>
                                    setSelectedFile(e.target.files[0])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        {isLoading ? (
                          <Loading />
                        ) : (
                          <div className="flex justify-end space-x-3">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              Create
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
