import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { getFarmer } from '../../services/api/farmers';
import Farms from "./Farms";
import EditFarmer from "../EditFarmer";
import Devices from "./Devices";
import Loading from "../shared/Loading";

export default function FarmerDetails() {
  const { farmerId } = useParams();
  const [farmer, setFarmer] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function refreshFarmer() {
      setIsLoading(true);
      const { data: result } = await getFarmer(farmerId)
      setFarmer(result)
      setIsLoading(false);
    }
    refreshFarmer()
  }, [farmerId])
  
  
  return (
    <>
      <div>
        <Sidebar selected="Farmers" />
        <div>
          <Topbar />
          <main className="md:pl-64 flex flex-col flex-1 px-4 sm:px-6">
            <div className="py-6 px-4 sm:flex sm:items-center">
              <div className="max-w-7xl sm:flex-auto mx-auto sm:px-6 md:px-8 flex justify-between items-center">
                <h1 className="text-2xl font-semibold text-gray-900">
                  {farmer.name}
                </h1>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <EditFarmer farmer={farmer} />
                </div>
              </div>
            </div>
            <div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {isLoading ? (
                  <Loading />
                ) : (
                  <div className="py-4">
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                          <div className="sm:col-span-3">
                            <dt className="text-sm font-medium text-gray-500">
                              Phone Number
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {farmer.phoneNumber || "-"}
                            </dd>
                          </div>

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Ghana Card
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {farmer.ghanaCardId || "-"}
                            </dd>
                          </div>

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Occupation
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {farmer.otherOccupation || "-"}
                            </dd>
                          </div>

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Preferred Language
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {farmer.language?.name || "-"}
                            </dd>
                          </div>

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Date Of Birth
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {farmer.dateOfBirth || "-"}
                            </dd>
                          </div>

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Gender
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {farmer.gender || "-"}
                            </dd>
                          </div>

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Disabilities
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {farmer.disabilities || "-"}
                            </dd>
                          </div>

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Education
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {farmer.educationalLevel || "-"}
                            </dd>
                          </div>

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Town
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {farmer.town || "-"}
                            </dd>
                          </div>

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              District
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {farmer.district || "-"}
                            </dd>
                          </div>

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Region
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {farmer.region || "-"}
                            </dd>
                          </div>

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Created At
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {dayjs(farmer.updatedAt).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </dd>
                          </div>

                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Last Updated At
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {dayjs(farmer.updatedAt).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>

                    <div className="mt-8">
                      <Farms farmerId={farmerId} farmer={farmer} />
                    </div>

                    <div className="mt-8">
                      <Devices farmerId={farmerId} farmer={farmer} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}