import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null,
  permissions: [],
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      if (!action.payload) {
        localStorage.removeItem('currentUser')
      } else {
        localStorage.setItem('currentUser', JSON.stringify(action.payload))
      }
      state.user = action.payload
    },

    setPermission: (state, action) => {
      if (action.payload) {
        state.permissions = action.payload
      } else {
        state.permissions = []
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUser, setPermission } = authSlice.actions

export const selectUser = (state) => {
  return state.auth?.user
}

export const selectPermissions = (state) => {
  return state.auth?.permissions
}

export const hasPermission = (state) => (actions = []) => {
  if (Array.isArray(actions)) {
    return actions.filter(action => state.auth?.permissions?.includes(action)).length
  }

  return state.auth?.permissions?.includes(actions)
}

export default authSlice.reducer
