import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { AssignFarmer, getFarmers } from "../../services/api/farmers";
import SearchFarmer from "./SearchFarmer";
import SweetAlert from "../shared/SweetAlert";
import Loading from "../shared/Loading";

export default function AssignAgent({
  defaultState = false,
  showButton = true,
  onClose = () => {},
  agent = {},
}) {
  useEffect(() => {
    setAgentId(agent.id);
    async function Farmer() {
      const { data: result } = await getFarmers();
      setFarmers(result);
    }
    Farmer();
  }, [agent]);

  const [open, setOpen] = useState(defaultState);
  const [farmerId, setFarmerId] = useState("");
  const [farmers, setFarmers] = useState([]);
  const [agentId, setAgentId] = useState(agent.id);
  const [dialogInfo, setDialogInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      agent_id: agentId,
      farmer_id: farmerId,
    };
    console.log("submit", data);
    setIsLoading(false);
    try {
      await AssignFarmer(data);
      setDialogInfo({
        title: "Success!",
        text: "Farmer Assigned",
        icon: "success",
        confirmButtonText: "Close",
      });
      setOpen(false);
      setTimeout(() => {
        window.location.reload();
      },2000);
      
    } catch (error) {
      setDialogInfo({
        title: "Falied!",
        text: "Updating this farm failed. Kindly refresh and try again.",
        icon: "error",
        confirmButtonText: "Close",
      });
      setOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  useEffect(() => {
    setOpen(defaultState);
  }, [defaultState]);

  useEffect(() => {
    if (!open) {
      onClose();
    }
  }, [open, onClose]);
  console.log("first", farmerId);

  return (
    <>
      <div>
        {!!showButton && (
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => setOpen(true)}
          >
            Assign A Farmer
          </button>
        )}
        {dialogInfo && (
          <SweetAlert
            title={dialogInfo.title}
            text={dialogInfo.text}
            icon={dialogInfo.icon}
            confirmButtonText={dialogInfo.confirmButtonText}
          />
        )}
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      onSubmit={handleFormSubmit}
                      action="POST"
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                    >
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                Farmer
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">Farmer.</p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                          {/* Farmer */}
                          <div className="space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5">
                            {/* <div className="mb-4">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Farmer
                              </h3>
                            </div> */}
                          </div>
                          <div className="space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div className="mb-4">
                              {/* <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Farmer
                              </h3> */}
                              <p className="mt-1 text-sm text-gray-500">
                                Select the Farmer you want to assign to this
                                agent by searching the name or selecting from
                                the list.
                              </p>
                            </div>
                          </div>

                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              {/* <label
                                htmlFor="farmer"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Farmer
                              </label> */}
                            </div>
                            <div className="sm:col-span-2">
                              <div className="mt-10">
                                <label className="text-lg pl-2">
                                  Select Farmer:
                                </label>
                                <SearchFarmer
                                  farmersData={farmers}
                                  setFarmerId={setFarmerId}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        {isLoading ? (
                          <Loading />
                        ) : (
                          <div className="flex justify-end space-x-3">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className={`inline-flex justify-center rounded-md border border-transparent  py-2 px-4 
                              text-sm font-medium  shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2
                              ${"bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 text-white"}`}
                            >
                              Assign Farmer
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
