import { API_BASE_URL } from '../constants'
import { get, patch, post, destroy } from './requests'

export const getFiles = async (options = {}) => {
  return await get(`${API_BASE_URL}/files`, options)
}

export const getFile = async (fileId, options = {}) => {
  return await get(`${API_BASE_URL}/files/${fileId}`, options)
}

export const postFiles = async (body, options = {}) => {
  return await post(`${API_BASE_URL}/files`, body, options)
}

export const patchFile = async (fileId, body, options = {}) => {
  return await patch(`${API_BASE_URL}/files/${fileId}`, body, options)
}

export const deleteFile = async (fileId, options = {}) => {
  return await destroy(`${API_BASE_URL}/files/${fileId}`, options)
}
