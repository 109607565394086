import { API_BASE_URL } from '../constants'
import { get, patch, post, destroy } from './requests'

export const getContents = async (options = {}) => {
  return await get(`${API_BASE_URL}/contents`, options)
}

export const getContent = async (contentId, options = {}) => {
  return await get(`${API_BASE_URL}/contents/${contentId}`, options)
}

export const postContents = async (body, options = {}) => {
  return await post(`${API_BASE_URL}/contents`, body, options)
}

export const patchContent = async (contentId, body, options = {}) => {
  return await patch(`${API_BASE_URL}/contents/${contentId}`, body, options)
}

export const deleteContent = async (contentId, options = {}) => {
  return await destroy(`${API_BASE_URL}/contents/${contentId}`, options)
}
