import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { getDevicesByFarmer } from "../../services/api/farmerDevices";
import NewFarmerDevice from "../NewFarmerDevice";
import EmptyState from "../shared/EmptyState";
import Loading from "../shared/Loading";

export default function Devices({ farmerId, farmer }) {
  const [devices, setDevices] = useState([])
  const [newFormState, setNewFormState] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function refreshDevices() {
      setIsLoading(true);
      const { data: result } = await getDevicesByFarmer(farmerId)
      setDevices(result)
      setIsLoading(false);
    }
    refreshDevices()
  }, [farmerId])
  
  return (
    <>
      <div>
        <main className="flex flex-col flex-1">
          <div className="pt-4 sm:flex sm:items-center">
            <div className="sm:flex-auto ">
              <h1 className="text-2xl font-semibold text-gray-900">
                Farmer Devices
              </h1>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <NewFarmerDevice
                defaultState={newFormState}
                showButton={true}
                onClose={() => setNewFormState(false)}
                farmer={farmer}
              />
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            {!devices.length ? (
              <div className="my-12 mb-24">
                <EmptyState
                  title="Farmer Device"
                  showButton={false}
                  onClick={() => setNewFormState(true)}
                />
              </div>
            ) : (
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                {isLoading ? (
                  <Loading />
                ) : (
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Farmer
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              OTP Code
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Configured By
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Added At
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Last Updated At
                            </th>
                            {/* <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Edit</span>
                            </th> */}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {devices.map((device) => (
                            <tr key={device.id}>
                              <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                                <a href={`/farmers/${device.farmer?.id}`}>
                                  {device.farmer?.name}
                                </a>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {device?.deviceName || "Unnamed"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {device?.otpCode || "-"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {device.user?.name || "-"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {dayjs(device.createdAt).format(
                                  "YYYY-MM-DD h:mm"
                                )}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {dayjs(device.updatedAt).format(
                                  "YYYY-MM-DD h:mm"
                                )}
                              </td>
                              {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <a
                                  href={`/farms/${device.id}`}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  View
                                </a>
                                <a
                                  href="/#"
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  Edit
                                </a>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </main>
      </div>
    </>
  );
}