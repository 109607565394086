import { API_BASE_URL } from '../constants'
import { get, post } from './requests'

export const getDevicesByFarmer = async (farmerId, options = {}) => {
  return get(`${API_BASE_URL}/farmers/${farmerId}/devices`, options)
}

export const postFarmerDevices = async (body, options = {}) => {
  return post(`${API_BASE_URL}/farmer-devices`, body, options)
}
