/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from "react";
import dayjs from "dayjs";
import { deletePractice, getPractices } from "../../services/api/practices";
import { getFarmPractices } from "../../services/api/farms";
import EmptyState from "../shared/EmptyState";
import NewFarmPractice from "../NewFarmPractice";
import Pagination from "../shared/Pagination";
import EditPractice from "../EditPractice";
import Swal from "sweetalert2";
import { TrashIcon } from "@heroicons/react/outline";
import SweetAlert from "../shared/SweetAlert";
import Loading from "../shared/Loading";

export default function PracticesList({
  defaultPractices = false,
  farmId = null,
  showNewButton = false,
  search = "",
}) {
  const [practices, setPractices] = useState([]);
  const [meta, setMeta] = useState({});
  const [dialogInfo, setDialogInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const refreshPractices = useCallback(
    async function refreshPractices(page = 1) {
      setIsLoading(true);
      const options = {
        params: {
          page,
        },
      };
      const { data: result, meta: resultsMeta } = farmId
        ? await getFarmPractices(farmId, options)
        : await getPractices(options);
      setPractices(result);
      setMeta(resultsMeta);
      setIsLoading(false);
    },
    [farmId]
  );

  useEffect(() => {
    if (!defaultPractices) {
      refreshPractices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmId, defaultPractices]);

  useEffect(() => {
    if (defaultPractices) {
      setPractices(defaultPractices);
    }
  }, [defaultPractices]);

  useEffect(() => {
    async function refreshPractices() {
      setIsLoading(true);
      const options = {
        params: {
          q: search,
        },
      };
      const { data: result } = farmId
        ? await getFarmPractices(farmId, options)
        : await getPractices(options);
      setPractices(result);
      setIsLoading(false);
    }

    if (farmId) {
      refreshPractices();
    }

    return () => {
      setPractices([]);
    };
  }, [farmId, search]);

  const handlePracticeDeletion = async (practiceId) => {
    setIsLoading(true);
    try {
      const { success, message } = await deletePractice(practiceId);
      console.log(message, success);
      // alert("Practice deleted successfully");
      setDialogInfo({
        title: "Success",
        text: "Practice deleted successfully.",
        icon: "success",
        confirmButtonText: "Close",
      });
      console.log(practiceId);
    } catch (error) {
      // alert("error deleting practice");
      setDialogInfo({
        title: "Failed",
        text: "Error deleting practice.",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      {dialogInfo && (
        <SweetAlert
          title={dialogInfo.title}
          text={dialogInfo.text}
          icon={dialogInfo.icon}
          confirmButtonText={dialogInfo.confirmButtonText}
        />
      )}
      <div className="flex flex-col">
        {isLoading ? (
          <Loading />
        ) : !practices.length ? (
          <div className="my-12 mb-24">
            <EmptyState title="Agronomical Practices" showButton={false} />
          </div>
        ) : (
          <>
            {showNewButton && (
              <div className="my-4 flex justify-end">
                <NewFarmPractice />
              </div>
            )}
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Added At
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Last Updated At
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {practices.map((practice) => (
                        <tr key={practice.id}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                            <a href={`/best-practices/${practice.id}`}>
                              {practice.title}
                            </a>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {practice.description}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {dayjs(practice.createdAt).format(
                              "YYYY-MM-DD HH:mm"
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {dayjs(practice.updatedAt).format(
                              "YYYY-MM-DD HH:mm"
                            )}
                          </td>
                          <td className="relative whitespace-nowrap justify-center items-center w-44 flex space-x-10 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <EditPractice practice={practice} />
                            <a
                              // href="#"
                              onClick={() => {
                                Swal.fire({
                                  title: "Do you want to Delete the practice?",
                                  showDenyButton: true,
                                  showCancelButton: true,
                                  confirmButtonText: "Delete",
                                  denyButtonText: `Don't delete`,
                                }).then((result) => {
                                  /* Read more about isConfirmed, isDenied below */

                                  if (result.isConfirmed) {
                                    handlePracticeDeletion(practice.id);

                                    Swal.fire(
                                      "Practice Deleted sub!",
                                      "",
                                      "success"
                                    );
                                  } else if (result.isDenied) {
                                    Swal.fire(
                                      "Practice not deleted",
                                      "",
                                      "info"
                                    );
                                  }
                                });
                              }}
                            >
                              <TrashIcon
                                color="red"
                                size={30}
                                className="h-6 w-6 cursor-pointer"
                              />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <Pagination
                    meta={meta}
                    onPrev={(page) => refreshPractices(page)}
                    onNext={(page) => refreshPractices(page)}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
