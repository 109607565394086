import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser, getCurrentUser } from "../../services/api/auth";
import { setUser } from "../../services/store/auth";
import SweetAlert from "../shared/SweetAlert";
import Loading from "../shared/Loading";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dialogInfo, setDialogInfo] = useState(null);

  const canLogin = () => {
    return email && password;
  };

  const attemptLogin = async (e) => {
    e.preventDefault();

    if (!canLogin()) {
      setDialogInfo({
        title: "Login Failed",
        text: "Invalid credentials provided",
        icon: "error",
        confirmButtonText: "Close",
      });
      return;
    }

    setIsLoading(true);
    try {
      const data = await LoginUser(email, password);
      localStorage.setItem("apiToken", data.token);
      const currentUser = await getCurrentUser();

      console.log({ currentUser });
      dispatch(setUser(currentUser));

      navigate("/");
    } catch (err) {
      console.log({ err });
      setDialogInfo({
        title: "Login Failed",
        text: err.message,
        icon: "error",
        confirmButtonText: "Close",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {dialogInfo && (
        <SweetAlert
          title={dialogInfo.title}
          text={dialogInfo.text}
          icon={dialogInfo.icon}
          confirmButtonText={dialogInfo.confirmButtonText}
        />
      )}
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-2 text-center text-xl font-bold text-gray-400">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form
                className="space-y-6"
                method="POST"
                action=""
                onSubmit={attemptLogin}
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {" "}
                    Email or Phone{" "}
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="text"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {" "}
                    Password or PIN{" "}
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      {" "}
                      Remember me{" "}
                    </label>
                  </div>
                </div>

                <div className="flex justify-center items-center">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <button
                      disabled={isLoading || !canLogin()}
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Sign in
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
