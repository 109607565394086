import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { getAgent, getAllAssignedFarmers } from "../../services/api/agents";
import AssignAgent from "./AssignAgent";
import Loading from "../shared/Loading";

export default function AgentDetails() {
  const { agentId } = useParams();
  const [agent, setAgent] = useState([]);
  const [farmers, setFarmers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function refreshAgent() {
      setIsLoading(true);
      const { data: result } = await getAgent(agentId);
      setAgent(result);
      setIsLoading(false);
    }
    refreshAgent();
  }, [agentId]);

  useEffect(() => {
    console.log(agent);
    async function refreshAgent() {
      const farmers = await getAllAssignedFarmers(agent.phoneNumber);
      console.log("=====||", farmers);
      setFarmers(farmers?.agent?.farmers || []);
    }
    // if (agent.email) {
    refreshAgent();
    // }
  }, [agent, agent.email]);

  return (
    <>
      <div>
        <Sidebar selected="Agents" />
        <div>
          <Topbar />
          <main className="md:pl-64 flex flex-col flex-1 px-4 sm:px-6">
            <div className="py-6">
              <div className="max-w-7xl mx-auto flex justify-between px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">
                  {agent.name}
                </h1>
                <AssignAgent agent={agent} />
              </div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {isLoading ? (
                  <Loading />
                ) : (
                  <div className="py-4">
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                          <div className="sm:col-span-3">
                            <dt className="text-sm font-medium text-gray-500">
                              Phone Number
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {agent.phoneNumber}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Created At
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {dayjs(agent.updatedAt).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Last Updated At
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {dayjs(agent.updatedAt).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Deleted At
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {dayjs(agent.deletedAt).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {isLoading ? (
                  <Loading />
                ) : (
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Phone Number
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Added At
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Last Updated At
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Actions</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {farmers.map((farmer) => (
                            <tr key={farmer.id}>
                              <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                                <a href={`/farmers/${farmer.id}`}>
                                  {farmer.name}
                                </a>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {farmer.phoneNumber}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {dayjs(farmer.createdAt).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {dayjs(farmer.updatedAt).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </td>
                              <td className="relative whitespace-nowrap justify-center items-center w-44 flex space-x-10 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <a
                                  href={`/farmers/${farmer.id}`}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  View
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {/* <Pagination
                        meta={meta}
                        onPrev={(page) => refreshAgent(page)}
                        onNext={(page) => refreshAgent(page)}
                      /> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
