import React, { useCallback } from "react";
import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  BellIcon,
  HomeIcon,
  MenuAlt2Icon,
  XIcon,
  UserGroupIcon,
  GlobeIcon,
  SunIcon,
  BookOpenIcon,
  ClipboardListIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import debounce from "lodash.debounce";
import { SearchIcon } from "@heroicons/react/solid";
import { postLogout } from "../services/api/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectPermissions, selectUser, setUser } from "../services/store/auth";
import { DebounceInput } from "react-debounce-input";
import { checkPermissionHelper } from "../services/helpers";
import { useEffect } from "react";

const navigation = [
  {
    name: "Home",
    href: "/",
    icon: HomeIcon,
    current: true,
    permissions: [
      "create agents",
      "read agents",
      "edit agents",
      "delete agents",
    ],
  },
  {
    name: "Farmers",
    href: "/farmers",
    icon: UserGroupIcon,
    current: false,
    permissions: [
      "create agents",
      "read agents",
      "edit agents",
      "delete agents",
    ],
  },
  {
    name: "Farms",
    href: "/farms",
    icon: GlobeIcon,
    current: false,
    permissions: [
      "create agents",
      "read agents",
      "edit agents",
      "delete agents",
    ],
  },
  {
    name: "Partners",
    href: "/partners",
    icon: UserGroupIcon,
    current: false,
    permissions: [
      "create partners",
      "read partners",
      "edit partners",
      "delete partners",
    ],
  },
  {
    name: "Agents",
    href: "/agents",
    icon: UserGroupIcon,
    current: false,
    permissions: [
      "create agents",
      "read agents",
      "edit agents",
      "delete agents",
    ],
  },
  {
    name: "Crops",
    href: "/crops",
    icon: SunIcon,
    current: false,
    permissions: ["create crops", "read crops", "edit crops", "delete crops"],
  },
  {
    name: "Files",
    href: "/files",
    icon: BookOpenIcon,
    current: false,
    permissions: ["create files", "read files", "edit files", "delete files"],
  },
  {
    name: "Contents",
    href: "/contents",
    icon: BookOpenIcon,
    current: false,
    permissions: [
      "create contents",
      "read contents",
      "edit contents",
      "delete contents",
    ],
  },
  {
    name: "Best Practices",
    href: "/best-practices",
    icon: ClipboardListIcon,
    current: false,
    permissions: [
      "create practices",
      "read practices",
      "edit practices",
      "delete practices",
    ],
  },
  {
    name: "Assigned Farmers",
    href: "/assign-farmers",
    icon: UsersIcon,
    current: false,
    permissions: ["create farms", "read farms", "edit farms", "delete farms"],
  },
];
const userNavigation = [
  // { name: "Your Profile", href: "#" },
  // { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Topbar({ onSearchUpdated = () => {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [search, setSearch] = useState("");

  const attemptLogout = async () => {
    await postLogout();
    dispatch(setUser(null));
    navigate("/login");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce((q) => onSearchUpdated(q), 300));

  const searchHandler = (event) => {
    setSearch(event.target.value);
    debouncedSearch(event.target.value);
  };

  const permissions = useSelector(selectPermissions);

  function checkPermission(actions = []) {
    return checkPermissionHelper(permissions, actions);
  }

  useEffect(() => {
    console.log("permissions ", permissions);
  }, [permissions]);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <h3 className="text-gray-900 text-xl font-bold">
                    Ndobai Admin
                  </h3>
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {navigation.map((item) =>
                      item.permissions === true ||
                      (permissions && checkPermission(item.permissions)) ? (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md",
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6",
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ) : (
                        ""
                      ),
                    )}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-between">
            <div className="flex-1 flex">
              <form className="w-full flex md:ml-0" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <DebounceInput
                    minLength={3}
                    debounceTimeout={300}
                    id="search-field"
                    className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                    placeholder="Search"
                    type="search"
                    name="search"
                    value={search}
                    onChange={searchHandler}
                  />
                </div>
              </form>
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              <button
                type="button"
                className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Profile dropdown */}
              <Menu as="div" className="mr-2 ml-3 relative">
                <div>
                  <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open user menu</span>
                    {/* <img
                  className="h-8 w-8 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                /> */}
                    <span>{user?.name || "User"}</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            href={item.href}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}
                            onClick={() =>
                              item.name === "Sign out" ? attemptLogout() : ""
                            }
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
