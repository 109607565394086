import { useState } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import NewPractice from "./NewPractice";
import PracticesList from "./Practices/PracticesList";

export default function Practices() {  
  const [searchFilters, setSearchFilters] = useState('')

  return (
    <>
      <div>
        <Sidebar selected="Best Practices" />
        <div>
          <Topbar
            onSearchUpdated={(s) => setSearchFilters(s)}
          />
          <main className="md:pl-64 flex flex-col flex-1 px-4 sm:px-6">
            <div className="pt-4 ml-4 sm:flex sm:items-center">
              <div className="sm:flex-auto ">
                <h1 className="text-2xl font-semibold text-gray-900">
                  Best Practices
                </h1>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <NewPractice/>
              </div>
            </div>
            <div className="px-4 mt-8 flex flex-col">
              <PracticesList
                search={searchFilters}
              />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}