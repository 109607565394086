import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { AssignFarmer, getFarmers, postFarmers } from "../services/api/farmers";
import { getLanguages } from "../services/api/languages";
import { useSelector } from "react-redux";
import { selectUser } from "../services/store/auth";
import SweetAlert from "./shared/SweetAlert";
import Loading from "./shared/Loading";
import { getAllAssignedFarmers } from "../services/api/agents";
const regions = require("../services/data/regions.json");
const regionNames = Object.keys(regions);

export default function NewFarmer() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [gender, setGender] = useState("");
  const [education, setEducation] = useState("");
  const [occupation, setOccupation] = useState("");
  const [language, setLanguage] = useState("");
  const [languages, setLanguages] = useState([]);
  const [disabilities, setDisabilities] = useState("");
  const [town, setTown] = useState("");
  const [district, setDistrict] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [districtsInRegion, setDistrictsInRegion] = useState([]);
  const [farmers, setFarmers] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [ghanaCardId, setGhanaCardId] = useState("");
  const [isAgent, setIsAgent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(selectUser);
  const [dialogInfo, setDialogInfo] = useState(null);
  const [agent, setAgent] = useState();

  async function refreshFarmers(page = false) {
    setIsLoading(true);

    // const { data: result, meta: resultMeta } = await getFarmers(options);
    console.log(user);
    const result = await getAllAssignedFarmers(user.email);
    // options
    console.log("incoming", result);
    setAgent(result?.agent);
    setIsLoading(false);
    setFarmers(result?.agent?.farmers);
    // setMeta(resultMeta);
  }

  useEffect(() => {
    refreshFarmers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedRegion) {
      setDistrictsInRegion(regions[selectedRegion]);
    } else {
      setDistrictsInRegion([]);
    }
  }, [selectedRegion]);

  useEffect(() => {
    async function fetchLanguages() {
      const { data: results } = await getLanguages();
      setLanguages(results);
    }
    fetchLanguages();
  }, []);

  async function assignFarmerAsync(selectedFarmerDetail) {
    setIsLoading(true);
    const options = {
      params: {
        q: name,
      },
    };

    const body = {
      agent_id: agent.id,
      farmer_id: selectedFarmerDetail,
    };

    const res = await AssignFarmer(body);

    console.log(res);

    const response = await getFarmers(options);
    console.log(response);
    // setMeta(resultMeta);
    setIsLoading(false);
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!name || !phone) {
      //alert("Please correct the form before continuing");
      setDialogInfo({
        title: "Failed",
        text: "Please correct the form before continuing.",
        icon: "error",
        confirmButtonText: "Close",
      });
      return;
    }
    if (phone.length < 9 || phone.length > 13) {
      //alert("A valid phone number must between 9 and 13 characters");
      setDialogInfo({
        title: "Failed",
        text: "A valid phone number must between 9 and 13 characters.",
        icon: "error",
        confirmButtonText: "Close",
      });
      return;
    }
    setIsLoading(true);

    const farmerData = {
      name,
      phone_number: phone,
      date_of_birth: date,
      gender,
      educational_level: education,
      language_id: language,
      other_occupation: occupation,
      disabilities,
      town,
      district,
      region: selectedRegion,
      ghana_card_id: ghanaCardId,
    };

    const data = new FormData();
    // data.append("profile_picture", selectedFile);
    Object.keys(farmerData).forEach((farmerDataKey) => {
      data.append(farmerDataKey, farmerData[farmerDataKey]);
    });

    console.log(user);

    const { success, message, data: res } = await postFarmers(data);
    console.log(res);
    setIsLoading(false);
    if (isAgent && success) {
      await assignFarmerAsync(res.id);
      console.log("is agent:", isAgent, farmers);
    }

    if (!success) {
      //alert("Saving this farmer failed. Kindly refresh and try again.");
      setDialogInfo({
        title: "Failed",
        text: "Saving this farmer failed. Kindly refresh and try again.",
        icon: "error",
        confirmButtonText: "Close",
      });
      setIsLoading(false);
    }

    //alert(message);
    setDialogInfo({
      title: "Success",
      text: message,
      icon: "success",
      confirmButtonText: "Close",
    });
    setOpen(false);
    window.location.reload();
    setIsLoading(false);
  };

  return (
    <>
      {dialogInfo && (
        <SweetAlert
          title={dialogInfo.title}
          text={dialogInfo.text}
          icon={dialogInfo.icon}
          confirmButtonText={dialogInfo.confirmButtonText}
        />
      )}
      <div>
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          onClick={() => setOpen(true)}
        >
          Create Farmer
        </button>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      onSubmit={handleFormSubmit}
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                    >
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                New farmer
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Create a new farmer.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Divider container */}
                        <div className="space-y-6 py-6 sm:divide-gray-200 sm:py-0">
                          {/* farmer name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="farmer-name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Farmer name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="farmer-name"
                                id="farmer-name"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Farmer phone number */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="farmer-phone"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Phone Number
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="farmer-phone"
                                id="farmer-phone"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Ghana Card ID */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="ghana-card-id"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Ghana Card ID
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="ghana-card-id"
                                id="ghana-card-id"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={ghanaCardId}
                                onChange={(e) => setGhanaCardId(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="profile_picture"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Profile Picture
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="file"
                                name="profile_picture"
                                id="profile_picture"
                                className="px-2 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                onChange={(e) =>
                                  setSelectedFile(e.target.files[0])
                                }
                              />
                            </div>
                          </div>

                          {/* Farmer Date of birth */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="date-of-birth"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Date Of Birth
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <p className="block text-xs text-gray-900 sm:mt-px sm:pb-1">
                                Use 1st January of the year, if the farmer does
                                not know their exact date of birth.
                              </p>
                              <input
                                type="date"
                                name="date-of-birth"
                                id="date-of-birth"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Gender */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="gender"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Gender
                              </label>
                            </div>
                            <select
                              value={gender}
                              className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <option value="">Select gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>

                          {/* Level Of Education */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="level-of-education"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Level Of Education
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="level-of-education"
                                id="level-of-education"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={education}
                                onChange={(e) => setEducation(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Disabilities */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="disabilities"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Disabilities
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="disabilities"
                                id="disabilities"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={disabilities}
                                onChange={(e) =>
                                  setDisabilities(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          {/* preferred language*/}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="language"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Preferred Language
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <select
                                value={language}
                                name="language_id"
                                id="language_id"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                onChange={(e) => setLanguage(e.target.value)}
                              >
                                <option>Select a Language</option>
                                {languages.map((lang, langIndex) => (
                                  <option key={langIndex} value={lang.id}>
                                    {" "}
                                    {lang.name}{" "}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          {/* other employment/occupation */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="occupation"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Other Occupation
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="occupation"
                                id="occupation"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={occupation}
                                onChange={(e) => setOccupation(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* town */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="town"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Town
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="town"
                                id="town"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={town}
                                onChange={(e) => setTown(e.target.value)}
                              />
                            </div>
                          </div>
                          {/* is agent? */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="town"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Add me as agent? check the button
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="checkbox"
                                name="isAgent"
                                id="isAgent"
                                checked={isAgent}
                                className=" w-12 rounded-full py-3 border border-green-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={isAgent}
                                onChange={(e) => setIsAgent(!isAgent)}
                              />
                            </div>
                          </div>

                          {/* Region */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="region"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Region
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <select
                                value={selectedRegion}
                                name="region"
                                id="region"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                onChange={(e) =>
                                  setSelectedRegion(e.target.value)
                                }
                              >
                                <option>Select region</option>
                                {regionNames.map((regionName, regIndex) => (
                                  <option key={regIndex} value={regionName}>
                                    {" "}
                                    {regionName}{" "}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* district*/}
                      {selectedRegion &&
                        districtsInRegion &&
                        districtsInRegion.length && (
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="district"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                District
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <select
                                value={district}
                                name="district"
                                id="district"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                onChange={(e) => setDistrict(e.target.value)}
                              >
                                <option>Select district</option>
                                {districtsInRegion.map((district, indx) => (
                                  <option key={indx} value={district.District}>
                                    {district.District}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        {/* {isLoading ? (
                          <Loading />
                        ) : ( */}
                        <div className="flex justify-end space-x-3">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Create
                          </button>
                        </div>
                        {/* )} */}
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
