import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { getCrops } from "../services/api/crops";
import { postFarms } from "../services/api/farms";
import SweetAlert from "./shared/SweetAlert";
import Loading from "./shared/Loading";

const regions = require("../services/data/regions.json");
const regionNames = Object.keys(regions);

export default function EditCrop({
  defaultState = false,
  showButton = true,
  onClose = () => {},
  crop = {},
}) {
  const [open, setOpen] = useState(defaultState);
  const [crops, setCrops] = useState([]);
  const [farmerId, setFarmerId] = useState("");
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);
  const [farmCrops, setFarmCrops] = useState([]);
  const [town, setTown] = useState("");
  const [district, setDistrict] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [districtsInRegion, setDistrictsInRegion] = useState([]);
  const [dialogInfo, setDialogInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   if (farmer && farmer.id) {
  //     setFarmerId(farmer.id);
  //   } else {
  //     setFarmerId("");
  //   }
  // }, [farmer]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let formattedFarmCrops = {};
    farmCrops.forEach((farmCrop) => {
      formattedFarmCrops[farmCrop.cropId] = {
        cropId: farmCrop.cropId,
        cropCapacity: farmCrop.cropCapacity,
      };
    });
    setIsLoading(true);
    const data = {
      farmer_id: farmerId,
      gps_location: JSON.stringify({
        lat: latitude,
        lon: longitude,
      }),
      town,
      district,
      region: selectedRegion,
      crops: formattedFarmCrops,
    };

    const { success, message } = await postFarms(data);
    setIsLoading(false);
    if (!success) {
      //alert("Saving this farmer failed. Kindly refresh and try again.");
      setDialogInfo({
        title: "Failed",
        text: "Saving this Crop failed. Kindly refresh and try again.",
        icon: "error",
        confirmButtonText: "Close",
      });
      return;
    }

    //alert(message);
    setDialogInfo({
      title: "Success",
      text: message,
      icon: "success",
      confirmButtonText: "Close",
    });
    setOpen(false);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const addFarmCrop = () => {
    setFarmCrops([
      ...farmCrops,
      {
        cropId: null,
        cropCapacity: null,
      },
    ]);
  };

  const updateFarmCrop = (index, key, value) => {
    const farmCrop = farmCrops[index];
    if (farmCrop) {
      farmCrop[key] = value;
      const newFarmCrops = [...farmCrops];
      newFarmCrops.splice(index, 1, farmCrop);
      setFarmCrops(newFarmCrops);
    }
  };

  const removeFarmCrop = (index) => {
    const newFarmCrops = [...farmCrops];
    newFarmCrops.splice(index, 1);
    setFarmCrops(newFarmCrops);
  };

  const getTotalFarmSize = () => {
    return farmCrops.reduce((acc, second) => {
      if (!second.cropCapacity) return acc;
      return acc + Number.parseFloat(second.cropCapacity);
    }, 0);
  };

  useEffect(() => {
    async function refreshCrops() {
      const { data: result } = await getCrops();
      setCrops(result);
    }
    refreshCrops();
  }, []);

  useEffect(() => {
    if (selectedRegion) {
      setDistrictsInRegion(regions[selectedRegion]);
    } else {
      setDistrictsInRegion([]);
    }
  }, [selectedRegion]);

  useEffect(() => {
    setOpen(defaultState);
  }, [defaultState]);

  useEffect(() => {
    if (!open) {
      onClose();
    }
  }, [open, onClose]);

  console.log("crop", crop);

  return (
    <>
      {dialogInfo && (
        <SweetAlert
          title={dialogInfo.title}
          text={dialogInfo.text}
          icon={dialogInfo.icon}
          confirmButtonText={dialogInfo.confirmButtonText}
        />
      )}
      <div>
        {!!showButton && (
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-2 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => setOpen(true)}
          >
            Edit
          </button>
        )}
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      onSubmit={handleFormSubmit}
                      action="POST"
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                    >
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                Edit Crop
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Edit a crop.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                          {/* Farmer */}
                          {/* <div className="space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div className="mb-4">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Farmer
                              </h3>
                            </div>
                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                              <div className="sm:col-span-3">
                                <div className="mt-1">
                                  {farmer && (
                                    <>
                                      <p>
                                        <span
                                          target="_blank"
                                          rel="noreferrer"
                                          className="underline font-semibold text-gray-800"
                                        >
                                          {farmer.name}
                                        </span>
                                      </p>
                                      <p>{farmer?.gender}</p>
                                      <p>{farmer?.phoneNumber}</p>
                                      <p>{farmer?.town}</p>
                                      <p>{farmer?.district}</p>
                                      <p>{farmer?.region}</p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div className="mb-4">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Farm Location
                              </h3>
                              <p className="mt-1 text-sm text-gray-500">
                                Add the location of the farm
                              </p>
                            </div>
                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="latitude"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Latitude
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="latitude"
                                    id="latitude"
                                    autoComplete="given-name"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    value={latitude}
                                    onChange={(e) =>
                                      setLatitude(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="longitude"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Longitude
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="longitude"
                                    id="longitude"
                                    autoComplete="family-name"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    value={longitude}
                                    onChange={(e) =>
                                      setLongitude(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="region"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Region
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <select
                                value={selectedRegion}
                                name="region"
                                id="region"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                onChange={(e) =>
                                  setSelectedRegion(e.target.value)
                                }
                              >
                                <option>Select region</option>
                                {regionNames.map((regionName, regIndex) => (
                                  <option key={regIndex} value={regionName}>
                                    {" "}
                                    {regionName}{" "}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div> */}

                          {/* district*/}
                          {/* <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="district"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                District
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              {selectedRegion &&
                              districtsInRegion &&
                              districtsInRegion.length ? (
                                <select
                                  value={district}
                                  name="district"
                                  id="district"
                                  className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  onChange={(e) => setDistrict(e.target.value)}
                                >
                                  <option>Select district</option>
                                  {districtsInRegion.map((district, indx) => (
                                    <option
                                      key={indx}
                                      value={district.District}
                                    >
                                      {district.District}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <p className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                                  Select a Region above ☝🏾
                                </p>
                              )}
                            </div>
                          </div> */}

                          {/* <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="town"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Town
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="town"
                                id="town"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={town}
                                onChange={(e) => setTown(e.target.value)}
                              />
                            </div>
                          </div> */}

                          {/*  */}
                          <div className="space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div className="">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Crops
                              </h3>
                              <p className="mt-1 text-sm text-gray-500">
                                Add all the crops grown on this farm.
                              </p>
                            </div>
                            <div className="mt-4">
                              {farmCrops.map((farmCrop, farmCropIndex) => (
                                <div
                                  key={farmCropIndex}
                                  className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
                                >
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Crop
                                    </label>
                                    <div className="mt-1">
                                      <select
                                        id="crop-id"
                                        name="crop-id"
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        value={farmCrop.cropId}
                                        onChange={(e) =>
                                          updateFarmCrop(
                                            farmCropIndex,
                                            "cropId",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option>Select a Crop</option>
                                        {crops.map((crop) => (
                                          <option value={crop.id}>
                                            {crop.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="last-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Size (Acres)
                                    </label>
                                    <div className="mt-1 flex flex-row">
                                      <input
                                        type="number"
                                        name="last-name"
                                        id="last-name"
                                        autoComplete="family-name"
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        value={farmCrop.cropCapacity}
                                        onChange={(e) =>
                                          updateFarmCrop(
                                            farmCropIndex,
                                            "cropCapacity",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <button
                                        type="button"
                                        className="rounded-md border border-gray-300 bg-white ml-2 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() =>
                                          removeFarmCrop(farmCropIndex)
                                        }
                                      >
                                        x
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <div className="flex flex-row justify-between flex-shrink-0 mt-2 py-3">
                                <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Total Farm Size
                                    </label>
                                    <div className="mt-1">
                                      <p>{getTotalFarmSize()}</p>
                                    </div>
                                  </div>
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="first-name"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Total Number of Crops
                                    </label>
                                    <div className="mt-1">
                                      <p>{farmCrops.length}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        {isLoading ? (
                          <Loading />
                        ) : (
                          <div className="flex justify-end space-x-3">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className={`inline-flex justify-center rounded-md border border-transparent  py-2 px-4 
                              text-sm font-medium  shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2
                              ${"bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 text-white"}`}
                            >
                              Update
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
