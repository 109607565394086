/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { deleteFarmer, getFarmers } from "../services/api/farmers";
import NewFarmer from "./NewFarmer";
import FarmersBulkUpload from "./Farmers/FarmersBulkUpload";
import EmptyState from "./shared/EmptyState";
import Loading from "./shared/Loading";
import Pagination from "./shared/Pagination";
import { TrashIcon } from "@heroicons/react/outline";
import Swal from "sweetalert2";
import SweetAlert from "./shared/SweetAlert";

export default function Farmers() {
  const [farmers, setFarmers] = useState([]);
  const [meta, setMeta] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [dialogInfo, setDialogInfo] = useState(null);

  async function refreshFarmers(page = false) {
    setIsLoading(true);
    const options = {
      params: {
        q: search,
      },
    };
    if (page) {
      options["params"]["page"] = page;
    }

    const { data: result, meta: resultMeta } = await getFarmers(options);
    setFarmers(result);
    setMeta(resultMeta);
    setIsLoading(false);
  }

  useEffect(() => {
    refreshFarmers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // handleFarmerDeletion;
  const handleFarmerDeletion = async (practiceId) => {
    setIsLoading(true);
    try {
      const { success, message } = await deleteFarmer(practiceId);
      console.log(message, success);
      // alert("Farmer deleted successfully");
      setDialogInfo({
        title: "Success",
        text: "Farmer deleted successfully",
        icon: "success",
        confirmButtonText: "Close",
      });
      console.log(practiceId);
    } catch (error) {
      // alert("error deleting farmer");
      setDialogInfo({
        title: "Failed",
        text: "Error deleting farmer.",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log(error);
    }
    setIsLoading(false);
    window.location.reload();
  };

  return (
    <>
      {dialogInfo && (
        <SweetAlert
          title={dialogInfo.title}
          text={dialogInfo.text}
          icon={dialogInfo.icon}
          confirmButtonText={dialogInfo.confirmButtonText}
        />
      )}
        <div>
          <Sidebar selected="Farmers" />
          <div>
            <Topbar onSearchUpdated={(s) => setSearch(s)} />
            <main className="md:pl-64 flex flex-col flex-1 px-4 sm:px-6">
              <div className="pt-4 ml-4 sm:flex sm:items-center">
                <div className="sm:flex-auto ">
                  <h1 className="text-2xl font-semibold text-gray-900">
                    Farmers
                  </h1>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 flex flex-row space-y-2 space-x-0 md:space-x-2 md:space-y-0">
                  <NewFarmer />
                  <FarmersBulkUpload />
                </div>
              </div>
              <div className="px-4 mt-8 flex flex-col">
                {isLoading ? (
                  <Loading />
                ) : !farmers.length ? (
                  <div className="my-12 mb-24">
                    <EmptyState title="Farmers" showButton={false} />
                  </div>
                ) : (
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Phone Number
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Added At
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Last Updated At
                              </th>
                              <th
                                scope="col"
                                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                              >
                                <span className="sr-only">Actions</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {farmers.map((farmer) => (
                              <tr key={farmer.id}>
                                <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                                  <a href={`/farmers/${farmer.id}`}>
                                    {farmer.name}
                                  </a>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {farmer.phoneNumber}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {dayjs(farmer.createdAt).format(
                                    "YYYY-MM-DD HH:mm"
                                  )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {dayjs(farmer.updatedAt).format(
                                    "YYYY-MM-DD HH:mm"
                                  )}
                                </td>
                                <td className="relative whitespace-nowrap justify-center items-center w-44 flex space-x-10 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <a
                                    href={`/farmers/${farmer.id}`}
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    View
                                  </a>
                                  <a
                                    // href="#"
                                    onClick={() => {
                                      Swal.fire({
                                        title:
                                          "Do you want to Delete the Farmer?",
                                        showDenyButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: "Delete",
                                        denyButtonText: `Don't delete`,
                                      }).then((result) => {
                                        /* Read more about isConfirmed, isDenied below */

                                        if (result.isConfirmed) {
                                          handleFarmerDeletion(farmer.id);

                                          Swal.fire(
                                            "Practice Deleted sub!",
                                            "",
                                            "success"
                                          );
                                        } else if (result.isDenied) {
                                          Swal.fire(
                                            "Practice not deleted",
                                            "",
                                            "info"
                                          );
                                        }
                                      });
                                    }}
                                  >
                                    <TrashIcon
                                      color="red"
                                      size={30}
                                      className="h-6 w-6 cursor-pointer"
                                    />
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <Pagination
                          meta={meta}
                          onPrev={(page) => refreshFarmers(page)}
                          onNext={(page) => refreshFarmers(page)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </main>
          </div>
        </div>
    </>
  );
}
