import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./services/store";
import Agents from "./components/Agents";
import Crops from "./components/Crops";
import Dashboard from "./components/Dashboard";
import Farmers from "./components/Farmers";
import Farms from "./components/FarmerFarms";
import Partners from "./components/Partners";
import Contents from "./components/Contents";
import Practices from "./components/Practices";
import PracticeDetails from "./components/Practices/PracticeDetails";
import FarmerDetails from "./components/Farmers/FarmerDetails";
import PartnerDetails from "./components/Partners/PartnerDetails";
import AgentDetails from "./components/Agents/AgentDetails";
import CropDetails from "./components/Crops/CropDetails";
import FileDetails from "./components/Files/FileDetails";
import ContentDetails from "./components/Contents/ContentDetails";
import Files from "./components/Files";
import FarmDetails from "./components/Farms/FarmDetails";
import Login from "./components/Auth/Login";
import { useEffect, useState } from "react";
import {
  selectPermissions,
  selectUser,
  setPermission,
  setUser,
} from "./services/store/auth";
import { getUserPermissions } from "./services/api/auth";
import { checkPermissionHelper } from "./services/helpers";
import AssignFarmers from "./components/Agents/AssignedFarmers";
import Loading from "./components/shared/Loading";

const AppWrapper = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const permissions = useSelector(selectPermissions);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const userFromStorage = localStorage.getItem("currentUser");
    dispatch(setUser(JSON.parse(userFromStorage)));
    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {}, [currentUser, permissions]);

  useEffect(() => {
    const fetchPermissions = async () => {
      setIsLoading(true);
      const userPermissions = await getUserPermissions();
      dispatch(setPermission(userPermissions));
      setIsLoading(false);
    };
    if (currentUser) {
      fetchPermissions();
    }
  }, [dispatch, currentUser]);

  function checkPermission(actions = []) {
    return checkPermissionHelper(permissions, actions);
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
        <Router>
          <Routes>
            {currentUser ? (
              <>
                <Route path="/" element={<Dashboard />} />
                {permissions &&
                  checkPermission([
                    "create partners",
                    "read partners",
                    "delete partners",
                    "edit partners",
                  ]) && (
                    <>
                      <Route path="/partners" element={<Partners />} />
                      <Route
                        path="/partners/:partnerId"
                        element={<PartnerDetails />}
                        exact={true}
                      />
                    </>
                  )}
                {permissions &&
                  checkPermission([
                    "create farmers",
                    "read farmers",
                    "delete farmers",
                    "edit farmers",
                  ]) && (
                    <>
                      <Route path="/farmers" element={<Farmers />} />
                      <Route
                        path="/farmers/:farmerId"
                        element={<FarmerDetails />}
                        exact={true}
                      />
                    </>
                  )}
                {permissions &&
                  checkPermission([
                    "create farmers",
                    "read farmers",
                    "delete farmers",
                    "edit farmers",
                  ]) && (
                    <>
                      <Route
                        path="/assign-farmers"
                        element={<AssignFarmers />}
                      />
                      <Route
                        path="/farmers/:farmerId"
                        element={<FarmerDetails />}
                        exact={true}
                      />
                    </>
                  )}
                {permissions &&
                  checkPermission([
                    "create farms",
                    "read farms",
                    "delete farms",
                    "edit farms",
                  ]) && (
                    <>
                      <Route path="/farms" element={<Farms />} />
                      <Route path="/farms/:farmId" element={<FarmDetails />} />
                    </>
                  )}
                {permissions &&
                  checkPermission([
                    "create agents",
                    "read agents",
                    "delete agents",
                    "edit agents",
                  ]) && (
                    <>
                      <Route path="/agents" element={<Agents />} />
                      <Route
                        path="/agents/:agentId"
                        element={<AgentDetails />}
                        exact={true}
                      />
                    </>
                  )}
                {permissions &&
                  checkPermission([
                    "create crops",
                    "read crops",
                    "delete crops",
                    "edit crops",
                  ]) && (
                    <>
                      <Route path="/crops" element={<Crops />} />
                      <Route
                        path="/crops/:cropId"
                        element={<CropDetails />}
                        exact={true}
                      />
                    </>
                  )}
                {permissions &&
                  checkPermission([
                    "create contents",
                    "read contents",
                    "delete contents",
                    "edit contents",
                  ]) && (
                    <>
                      <Route path="/contents" element={<Contents />} />
                      <Route
                        path="/contents/:contentId"
                        element={<ContentDetails />}
                        exact={true}
                      />
                    </>
                  )}
                {permissions &&
                  checkPermission([
                    "create files",
                    "read files",
                    "delete files",
                    "edit files",
                  ]) && (
                    <>
                      <Route path="/files" element={<Files />} />
                      <Route
                        path="/files/:fileId"
                        element={<FileDetails />}
                        exact={true}
                      />
                    </>
                  )}
                {permissions &&
                  checkPermission([
                    "create practices",
                    "read practices",
                    "delete practices",
                    "edit practices",
                  ]) && (
                    <>
                      <Route
                        path="/best-practices"
                        element={<Practices />}
                        exact={true}
                      />
                      <Route
                        path="/best-practices/:practiceId"
                        element={<PracticeDetails />}
                        exact={true}
                      />
                    </>
                  )}
              </>
            ) : (
              <>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
              </>
            )}
          </Routes>
        </Router>
    
  );
};

function App() {
  return (
    <Provider store={store}>
      <AppWrapper />
    </Provider>
  );
}

export default App;
