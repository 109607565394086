/* This example requires Tailwind CSS v2.0+ */
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import { MapIcon, SunIcon, UserGroupIcon } from "@heroicons/react/outline";
import { useEffect, useMemo, useState } from "react";
import { checkPermissionHelper } from "../../services/helpers";
import { useSelector } from "react-redux";
import { selectPermissions, selectUser } from "../../services/store/auth";
import { getAllAssignedFarmers } from "../../services/api/agents";

function formatStats(stats) {
  return Object.keys(stats).map((key) => {
    let icon;
    let permissions;
    let name;
    let destination;

    switch (key) {
      case "farms":
        icon = MapIcon;
        permissions = [
          "create agents",
          "read agents",
          "edit agents",
          "delete agents",
        ];
        break;

      case "totalHectares":
        name = "Total Farm Size (Acres)"
        destination = "farms"
        icon = MapIcon;
        permissions = [
          "create agents",
          "read agents",
          "edit agents",
          "delete agents",
        ];
        break;
      
      case "crops":
        icon = SunIcon;
        permissions = [
          "create agents",
          "read agents",
          "edit agents",
          "delete agents",
        ];
        break;

      case "farmer":
      default:
        icon = UserGroupIcon;
        permissions = [
          "create agents",
          "read agents",
          "edit agents",
          "delete agents",
        ];
        break;
    }

    return {
      id: destination ? destination.toLowerCase() : key.toLowerCase(),
      name: name ?? key,
      stat: Intl.NumberFormat().format(stats[key]),
      icon,
      permissions,
      change: "0",
      changeType: "increase",
    };
  });
}
const projects = [
  {
    name: "Assigned Farmers",
    initials: "AF",
    href: "/assign-farmers",
    members: "",
    bgColor: "bg-green-600",
  },
];

export default function Stats({ stats = [], agentStats = [] }) {
  const permissions = useSelector(selectPermissions);
  const user = useSelector(selectUser);
  const [farmers, setFarmers] = useState([]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function checkPermission(actions = []) {
    return checkPermissionHelper(permissions, actions);
  }

  const formattedStats = useMemo(() => formatStats(stats), [stats]);
  
  useEffect(() => {
    const fetchAssignedFarmers = async () => {
      const result = await getAllAssignedFarmers(user.email);
      setFarmers(result?.agent?.farmers || []);
    };
    
    fetchAssignedFarmers();
  }, []);

  useEffect(() => {
  }, [formattedStats]);

  return (
    <div>
      <h3 className="text-lg leading-6 font-medium text-gray-900">All time</h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {formattedStats.map((item) =>
          checkPermission(item.permissions) ? (
            <div
              key={item.id}
              className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
            >
              <dt>
                <div className="absolute bg-indigo-500 rounded-md p-3">
                  <item.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-16 text-sm font-medium text-gray-500 capitalize truncate">
                  {item.name}
                </p>
              </dt>
              <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                  {item.stat}
                </p>
                <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <a
                      href={`/${item.id}`}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {" "}
                      View all
                      <span className="sr-only"> {item.name} stats</span>
                    </a>
                  </div>
                </div>
              </dd>
            </div>
          ) : (
            ""
          ),
        )}
        {!checkPermission([
          "create agents",
          "read agents",
          "edit agents",
          "delete agents",
        ]) && (
          <div>
            <h2 className="text-sm font-medium text-gray-500">Agents Stats</h2>
            <ul role="list" className="mt-3">
              {projects.map((project) => (
                <li
                  key={project.name}
                  className=" flex rounded-md shadow-sm w-[300px]"
                >
                  <div
                    className={classNames(
                      project.bgColor,
                      "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white",
                    )}
                  >
                    {project.initials}
                  </div>
                  <div className="flex flex-1 items-center justify-between w-[200px] rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                    <div className="flex-1 truncate px-4 py-2 text-sm">
                      <a
                        href={project.href}
                        className="font-medium text-gray-900 hover:text-gray-600"
                      >
                        {project.name}
                      </a>
                      <p className="text-gray-500">{farmers?.length || 0} Farmers</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </dl>
    </div>
  );
}
