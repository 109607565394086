import { API_BASE_URL } from '../constants'
import { get, patch, post, destroy } from './requests'

export const getFarms = async (options = {}) => {
  return await get(`${API_BASE_URL}/farms`, options)
}

export const getFarmsByFarmer = async (farmerId, options = {}) => {
  return await get(`${API_BASE_URL}/farmers/${farmerId}/farms`, options)
}

export const getFarm = async (farmId, options = {}) => {
  return await get(`${API_BASE_URL}/farms/${farmId}`, options)
}

export const getFarmPractices = async (farmId, options = {}) => {
  return await get(`${API_BASE_URL}/farms/${farmId}/practices`, options)
}

export const postFarms = async (body, options = {}) => {
  return await post(`${API_BASE_URL}/farms`, body, options)
}

export const postFarmPractices = async (farmId, body, options = {}) => {
  return await post(`${API_BASE_URL}/farms/${farmId}/practices`, body, options)
}

export const patchFarm = async (farmId, body, options = {}) => {
  return await patch(`${API_BASE_URL}/farms/${farmId}`, body, options)
}

export const deleteFarm = async (farmId, options = {}) => {
  return await destroy(`${API_BASE_URL}/farms/${farmId}`, options)
}
