import { useEffect } from "react";
import Swal from "sweetalert2";

const SweetAlert = ({ title, text, icon, confirmButtonText }) => {
  const showSweetAlert = () => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText: confirmButtonText,
      //timer: 2000
    });
  };

  useEffect(() => {
    showSweetAlert();
  },[]);

  return null;
};

export default SweetAlert;
