import { API_BASE_URL } from '../constants'
import { get, patch, post, destroy } from './requests'

export const getPracticeSchedules = async (practiceId, options = {}) => {
  return await get(
    `${API_BASE_URL}/practices/${practiceId}/schedules`,
    options
  )
}

export const getPracticeSchedule = async (practiceId, scheduleId, options = {}) => {
  return await get(
    `${API_BASE_URL}/practices/${practiceId}/schedules/${scheduleId}`,
    options
  )
}

export const postPracticeSchedules = async (practiceId, body, options = {}) => {
  return await post(
    `${API_BASE_URL}/practices/${practiceId}/schedules`,
    body,
    options
  )
}

export const patchPracticeSchedule = async (practiceId, scheduleId, body, options = {}) => {
  return await patch(
    `${API_BASE_URL}/practices/${practiceId}/schedules/${scheduleId}`, 
    body,
    options
  )
}

export const deletePracticeSchedule = async (practiceId, scheduleId, options = {}) => {
  return await destroy(
    `${API_BASE_URL}/practices/${practiceId}/schedules/${scheduleId}`,
    options
  )
}
