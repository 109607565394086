import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { getAllAssignedFarmers } from "../../services/api/agents";
import NewFarmer from "../NewFarmer";
import FarmersBulkUpload from "../Farmers/FarmersBulkUpload";
import EmptyState from "../shared/EmptyState";
import Loading from "../shared/Loading";
import Pagination from "../shared/Pagination";
import { useSelector } from "react-redux";
import { selectUser } from "../../services/store/auth";

export default function AssignFarmers() {
  const [farmers, setFarmers] = useState([]);
  const [meta, setMeta] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const user = useSelector(selectUser);

  async function refreshFarmers(page = false) {
    setIsLoading(true);
    const options = {
      params: {
        q: search,
      },
    };
    if (page) {
      options["params"]["page"] = page;
    }

    const result = await getAllAssignedFarmers(user.email, options);
    // options
    if (result && result?.agent) {
      setIsLoading(false);
      setFarmers(result?.agent?.farmers || []);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    refreshFarmers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      <div>
        <Sidebar selected="Farmers" />
        <div>
          <Topbar onSearchUpdated={(s) => setSearch(s)} />
          <main className="md:pl-64 flex flex-col flex-1 px-4 sm:px-6">
            <div className="pt-4 ml-4 sm:flex sm:items-center">
              <div className="sm:flex-auto ">
                <h1 className="text-2xl font-semibold text-gray-900">
                  Farmers
                </h1>
              </div>

              <div className="mt-4 sm:mt-0 sm:ml-16 flex flex-row space-y-2 space-x-0 md:space-x-2 md:space-y-0">
                <NewFarmer />
                <FarmersBulkUpload />
              </div>
            </div>
            <div className="px-4 mt-8 flex flex-col">
              {isLoading ? (
                <Loading />
              ) : !farmers?.length ? (
                <div className="my-12 mb-24">
                  <EmptyState title="Farmer" showButton={false} />
                </div>
              ) : (
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Phone Number
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Added At
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Last Updated At
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Actions</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {farmers?.map((farmer) => (
                            <tr key={farmer.id}>
                              <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                                <a href={`/farmers/${farmer.id}`}>
                                  {farmer.name}
                                </a>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {farmer.phoneNumber}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {dayjs(farmer.createdAt).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {dayjs(farmer.updatedAt).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <a
                                  href={`/farmers/${farmer.id}`}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  View
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <Pagination
                        meta={meta}
                        onPrev={(page) => refreshFarmers(page)}
                        onNext={(page) => refreshFarmers(page)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
