import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { getFarm, postFarmPractices } from "../services/api/farms";
import { useParams } from "react-router-dom";
import { getPractices } from "../services/api/practices";
import SweetAlert from "./shared/SweetAlert";
import Loading from "./shared/Loading";

export default function NewFarmPractice({
  defaultState = false,
  showButton = true,
  onClose = () => {},
}) {
  const { farmId } = useParams();

  const [open, setOpen] = useState(defaultState);
  const [farm, setFarm] = useState(null);
  const [practices, setPractices] = useState([]);
  const [selectedPractice, setSelectedPractice] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dialogInfo, setDialogInfo] = useState(null);

  const isValidForm = () => {
    if (!selectedPractice) {
      return false;
    }
    if (!farmId) {
      return false;
    }
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!isValidForm()) {
      //alert("Please correct the form before continuing");
      setDialogInfo({
        title: "Failed",
        text: "Please correct the form before continuing.",
        icon: "error",
        confirmButtonText: "Close",
      });
      return;
    }
    setIsLoading(true);

    const data = {
      practices: {
        [selectedPractice]: {
          id: selectedPractice,
        },
      },
    };

    const { success, message } = await postFarmPractices(farmId, data);
    setIsLoading(false);
    if (!success) {
      //alert(
      //  "Attaching this practice to Farm failed. Kindly refresh and try again."
      //);
      setDialogInfo({
        title: "Failed",
        text: "Attaching this practice to Farm failed. Kindly refresh and try again.",
        icon: "error",
        confirmButtonText: "Close",
      });
      return;
    }

    //alert(message);
    setDialogInfo({
      title: "Success",
      text: message,
      icon: "success",
      confirmButtonText: "Close",
    });
    setOpen(false);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const latLonValue = (location) => {
    try {
      location = JSON.parse(location);
      return [location.lat, location.lon].join(",");
    } catch {
      return location;
    }
  };

  useEffect(() => {
    async function refreshFarm() {
      const { data: result } = await getFarm(farmId);
      setFarm(result);
    }
    refreshFarm();
  }, [farmId]);

  useEffect(() => {
    async function refreshPractices() {
      const { data: result } = await getPractices();
      setPractices(result);
    }
    refreshPractices();
  }, []);

  useEffect(() => {
    setOpen(defaultState);
  }, [defaultState]);

  useEffect(() => {
    if (!open) {
      onClose();
    }
  }, [open, onClose]);

  return (
    <>
      {dialogInfo && (
        <SweetAlert
          title={dialogInfo.title}
          text={dialogInfo.text}
          icon={dialogInfo.icon}
          confirmButtonText={dialogInfo.confirmButtonText}
        />
      )}
      <div>
        {!!showButton && (
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => setOpen(true)}
          >
            Attach Agronomic Practice to Farm
          </button>
        )}
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      onSubmit={handleFormSubmit}
                      action="POST"
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                    >
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                Add Agronomic Practice to Farm
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Select a farm and an agronomical practice to
                                attach to that farm.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                          <div className="space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div className="mb-4">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Farm Details
                              </h3>
                              <p className="mt-1 text-sm text-gray-500">
                                Details of the Farm
                              </p>
                            </div>
                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="latitude"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Location
                                </label>
                                <div className="mt-1">
                                  {farm && (
                                    <>
                                      <p>
                                        <a
                                          href={`https://maps.google.com?q=${latLonValue(
                                            farm.gpsLocation
                                          )}`}
                                          target="_blank"
                                          rel="noreferrer"
                                          className="underline font-semibold text-gray-800"
                                        >
                                          {latLonValue(farm.gpsLocation)}
                                        </a>
                                      </p>
                                      <p>{farm?.town}</p>
                                      <p>{farm?.district}</p>
                                      <p>{farm?.region}</p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div className="">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Agronomic Practice
                              </h3>
                              <p className="mt-1 text-sm text-gray-500">
                                Add an agronomical practice to this farm.
                              </p>
                            </div>
                            <div className="mt-4">
                              <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                <div className="sm:col-span-6">
                                  <label
                                    htmlFor="first-name"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Practice
                                  </label>
                                  <div className="mt-1">
                                    <select
                                      id="practice-id"
                                      name="practice-id"
                                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                      value={selectedPractice}
                                      onChange={(e) =>
                                        setSelectedPractice(e.target.value)
                                      }
                                    >
                                      <option>Select a Practice</option>
                                      {practices.map((practice) => (
                                        <option
                                          key={practice.id}
                                          value={practice.id}
                                        >
                                          {practice.title}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        {isLoading ? (
                          <Loading />
                        ) : (
                          <div className="flex justify-end space-x-3">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={!isValidForm()}
                              type="submit"
                              className={`inline-flex justify-center rounded-md border border-transparent  py-2 px-4 
                              text-sm font-medium  shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2
                              ${
                                !isValidForm()
                                  ? "bg-gray-300 text-gray-600"
                                  : "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 text-white"
                              }`}
                            >
                              Create
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
