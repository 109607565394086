import axios from "axios";

// axios.defaults.withCredentials = true;

const responseSuccessHandler = (response) => {
  return response;
};

const responseErrorHandler = (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem("currentUser");
    window.location.href = "/login";
  }

  return Promise.reject(error);
};

axios.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

export default axios;
