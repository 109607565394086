import { get, patch, post, destroy } from './requests'
import { API_BASE_URL } from '../constants'

export const getPartners = async (options = {}) => {
  return await get(`${API_BASE_URL}/partners`, options)
}

export const getPartner = async (partnerId, options = {}) => {
  return await get(`${API_BASE_URL}/partners/${partnerId}`, options)
}

export const postPartners = async (body, options = {}) => {
  return await post(`${API_BASE_URL}/partners`, body, options)
}

export const patchPartner = async (partnerId, body, options = {}) => {
  return await patch(`${API_BASE_URL}/partners/${partnerId}`, body, options)
}

export const deletePartner = async (partnerId, options = {}) => {
  return await destroy(`${API_BASE_URL}/partners/${partnerId}`, options)
}
