import { get, patch, post, destroy, getSingle } from "./requests";
import { API_BASE_URL } from "../constants";

export const getAgents = async (options = {}) => {
  return await get(`${API_BASE_URL}/agents`, options);
};

export const getAgent = async (agentId, options = {}) => {
  return await get(`${API_BASE_URL}/agents/${agentId}`, options);
};

export const postAgents = async (body, options = {}) => {
  return await post(`${API_BASE_URL}/agents`, body, options);
};

export const patchAgent = async (agentId, body, options = {}) => {
  return await patch(`${API_BASE_URL}/agents/${agentId}`, body, options);
};

export const deleteAgent = async (agentId, options = {}) => {
  return await destroy(`${API_BASE_URL}/agents/${agentId}`, options);
};
//

// fetch all the assign farmers
export const getAllAssignedFarmers = async (agentEmail, options = {}) => {
  return await getSingle(
    `${API_BASE_URL}/fetchagentfarmer/${agentEmail}`,
    options
  );
};
