

export default function Pagination ({
  meta = {},
  onPrev = () => {},
  onNext = () => {},
}) {
  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{meta?.from}</span> to <span className="font-medium">{meta?.to}</span> of {' '}
          <span className="font-medium">{meta?.total}</span> results
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        {
          meta?.currentPage > 1 && (
            <div
              className="cursor-pointer relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              onClick={() => onPrev(meta?.currentPage - 1)}
              >
              Previous
            </div>
          )
        }
        {
          meta?.currentPage < meta?.lastPage && (
            <div
              className="cursor-pointer relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              onClick={() => onPrev(meta?.currentPage + 1)}
            >
              Next
            </div>
          )
        }
      </div>
    </nav>
  )
}