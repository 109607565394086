import { camelizeKeys } from "humps";
import axios from "./http";
import Swal from "sweetalert2";

// Function to extract CSRF token from cookie

const getDefaultOptions = async () => {
  // const csrfToken = getCsrfTokenFromCookies(); // Retrieve CSRF token from cookies

  const defaultOptions = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const token = window.localStorage.getItem("apiToken");
  if (token) {
    defaultOptions.headers["Authorization"] = `Bearer ${token}`;
  }

  return defaultOptions;
};

const handleRequestFailure = (response) => {
  console.error("The Request Failed", { response });
};

export async function get(url, options) {
  try {
    const defaultOptions = await getDefaultOptions();
    const { data } = await axios.get(url, {
      ...defaultOptions,
      ...options,
    });
    return camelizeKeys(data);
  } catch (err) {
    handleRequestFailure(err);
  }
}

export async function getSingle(url, options) {
  try {
    const defaultOptions = await getDefaultOptions();
    const { data } = await axios.get(url, {
      ...defaultOptions,
      ...options,
    });
    console.log("check", camelizeKeys(data?.agent));
    return data;
  } catch (err) {
    handleRequestFailure(err);
  }
}

export async function post(url, body, options) {
  try {
    const defaultOptions = await getDefaultOptions();
    const { data } = await axios.post(url, body, {
      ...defaultOptions,
      ...options,
    });
    console.log("data====>", data);

    return camelizeKeys(data);
  } catch (err) {
    console.log("=====>", JSON.stringify(err));
    Swal.fire({
      title: err.message,
      text: "Something went wrong. Check the details you provided.",
      icon: "warning",
      confirmButtonText: "Close",
    });
    handleRequestFailure(err);
  }
}

export async function patch(url, body, options) {
  try {
    const defaultOptions = await getDefaultOptions();
    const { data } = await axios.patch(url, body, {
      ...defaultOptions,
      ...options,
    });
    return camelizeKeys(data);
  } catch (err) {
    handleRequestFailure(err);
  }
}

export async function destroy(url, options) {
  try {
    const defaultOptions = await getDefaultOptions();
    return await axios.delete(url, {
      ...defaultOptions,
      ...options,
    });
  } catch (err) {
    handleRequestFailure(err);
  }
}
