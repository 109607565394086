import { API_BASE_URL } from '../constants'
import { get, patch, post, destroy } from './requests'

export const getPractices = async (options = {}) => {
  return await get(`${API_BASE_URL}/practices`, options)
}

export const getPractice = async (practiceId, options = {}) => {
  return await get(`${API_BASE_URL}/practices/${practiceId}`, options)
}

export const postPractices = async (body, options = {}) => {
  return await post(`${API_BASE_URL}/practices`, body, options)
}

export const patchPractice = async (practiceId, body, options = {}) => {
  return await patch(`${API_BASE_URL}/practices/${practiceId}`, body, options)
}

export const deletePractice = async (practiceId, options = {}) => {
  return await destroy(`${API_BASE_URL}/practices/${practiceId}`, options)
}
