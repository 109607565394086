/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import NewFarm from "../NewFarm";
import { deleteFarm, getFarmsByFarmer } from "../../services/api/farms";
import EmptyState from "../shared/EmptyState";
import Swal from "sweetalert2";
import { TrashIcon } from "@heroicons/react/outline";
import SweetAlert from "../shared/SweetAlert";
import Loading from "../shared/Loading";

export default function Farms({ farmerId, farmer }) {
  const [farms, setFarms] = useState([]);
  const [farmForm, setFarmForm] = useState(false);
  const [dialogInfo, setDialogInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const latLonValue = (location) => {
    try {
      location = JSON.parse(location);
      return [location.lat, location.lon].join(",");
    } catch {
      return location;
    }
  };

  useEffect(() => {
    async function refreshFarms() {
      setIsLoading(true);
      const { data: result } = await getFarmsByFarmer(farmerId);
      setFarms(result);
      setIsLoading(false);
    }
    refreshFarms();
  }, [farmerId]);
  const handleFarmDeletion = async (farmId) => {
    setIsLoading(true);
    try {
      const { success, message } = await deleteFarm(farmId);
      console.log(message, success);
      // alert("Farm deleted successfully");
      setDialogInfo({
        title: "Success",
        text: "Farm deleted successfully.",
        icon: "success",
        confirmButtonText: "Close",
      });
      console.log(farmId);
      setIsLoading(false);
    } catch (error) {
      // alert("error deleting farm");
      setDialogInfo({
        title: "Failed",
        text: "Error deleting farm.",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log(error);
    }
    window.location.reload();
  };
  return (
    <>
      {dialogInfo && (
        <SweetAlert
          title={dialogInfo.title}
          text={dialogInfo.text}
          icon={dialogInfo.icon}
          confirmButtonText={dialogInfo.confirmButtonText}
        />
      )}
        <div>
          <main className="flex flex-col flex-1">
            <div className="pt-4 sm:flex sm:items-center">
              <div className="sm:flex-auto ">
                <h1 className="text-2xl font-semibold text-gray-900">Farms</h1>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <NewFarm
                  defaultState={farmForm}
                  showButton={true}
                  onClose={() => setFarmForm(false)}
                  farmer={farmer}
                />
              </div>
            </div>
            <div className="mt-8 flex flex-col">
              {!farms.length ? (
                <div className="my-12 mb-24">
                  <EmptyState
                    title="Farm"
                    showButton={false}
                    onClick={() => setFarmForm(true)}
                  />
                </div>
              ) : (
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Location
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              GPS
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Number of Crops
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Farm Size (Acres)
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Last Updated At
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {farms.map((farm) => (
                            <tr key={farm.id}>
                              <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                                {[farm.town, farm.district, farm.region]
                                  .filter((a) => a)
                                  .join(", ")}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <a
                                  href={`https://maps.google.com?q=${latLonValue(
                                    farm.gpsLocation
                                  )}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="underline font-semibold text-gray-800"
                                >
                                  {latLonValue(farm.gpsLocation)}
                                </a>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {farm.numberOfCrops}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {farm.totalFarmSize}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {dayjs(farm.updatedAt).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <a
                                  href={`/farms/${farm.id}`}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  View
                                </a>
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <a
                                  // href="#"
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Do you want to Delete the Farm?",
                                      showDenyButton: true,
                                      showCancelButton: true,
                                      confirmButtonText: "Delete",
                                      denyButtonText: `Don't delete`,
                                    }).then((result) => {
                                      /* Read more about isConfirmed, isDenied below */

                                      if (result.isConfirmed) {
                                        handleFarmDeletion(farm.id);
                                        Swal.fire(
                                          "Farm Deleted sub!",
                                          "",
                                          "success"
                                        );
                                      } else if (result.isDenied) {
                                        Swal.fire(
                                          "Farm not deleted",
                                          "",
                                          "info"
                                        );
                                      }
                                    });
                                  }}
                                >
                                  <TrashIcon
                                    color="red"
                                    size={30}
                                    className="h-6 w-6"
                                  />
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </main>
        </div>
    </>
  );
}
