import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { getFile } from '../../services/api/files';
import Loading from "../shared/Loading";


export default function FileDetails() {
  const { fileId } = useParams();
  const [file, setFile] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function refreshFile() {
      setIsLoading(true);
      const { data: result } = await getFile(fileId)
      setFile(result)
      setIsLoading(false);
    }
    refreshFile()
  }, [fileId])
  
  return (
    <>
      <div>
        <Sidebar selected="Files" />
        <div>
          <Topbar />
          <main className="md:pl-64 flex flex-col flex-1 px-4 sm:px-6">
            <div className="py-6">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">
                  {file.name}
                </h1>
              </div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {isLoading ? (
                  <Loading />
                ) : (
                  <div className="py-4">
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                          <div className="sm:col-span-3">
                            <dt className="text-sm font-medium text-gray-500">
                              Type
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {file.type}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Created At
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {dayjs(file.updatedAt).format("YYYY-MM-DD HH:mm")}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Last Updated At
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {dayjs(file.updatedAt).format("YYYY-MM-DD HH:mm")}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Deleted At
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {dayjs(file.deletedAt).format("YYYY-MM-DD HH:mm")}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}