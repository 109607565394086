import React, { useState } from "react";
import * as XLSX from "xlsx";
import { postFarmers } from "../../../services/api/farmers";
import { postFarms } from "../../../services/api/farms";
import axios from "axios";
import { getCrops } from "../../../services/api/crops";
import Spinner from "react-spinkit";
import SweetAlert from "../../shared/SweetAlert";

const UploadBulkFarmers = () => {
  const [excelData, setExcelData] = useState([]);
  const [Crops, setCrops] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [dialogInfo, setDialogInfo] = useState(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Assuming headers are in the first row
        const headers = [
          "Farmer Name",
          "Ghana Card ID",
          "Age",
          "Gender",
          "Education",
          "Telephone No.",
          "Town",
          "Region",
          "District",
          "Geolocation",
          "Disability",
          "Other Employment/occupation",
          "Preferred Language",
          "Total Farm Size (Acres)",
          "No. of Crops under cultivation",
          "Crop 1",
          "Crop 1 Size (Acres)",
          "Crop 2",
          "Crop 2 Size (Acres)",
          "Crop 3",
          "Crop 3 Size (Acres)",
        ];

        const dataArr = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const excelValues = dataArr
          .slice(1) // Skip header row
          .map((row) => {
            const rowData = {};
            row.forEach((value, index) => {
              rowData[headers[index]] = value;
            });

            return rowData;
          });

        setExcelData(excelValues);
        console.log("first====", dataArr);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const convertDataKeys = () => {
    const mappedFarmers = excelData.map((farmer) => {
      return {
        farmer: {
          name: farmer["Farmer Name"] || "",
          ghana_card_id: farmer["Ghana Card ID"] || "",
          age: farmer["Age"] || 0,
          gender: farmer["Gender"] || "",
          education: farmer["Education"] || "",
          phone_number: farmer["Telephone No."] || "",
          district: farmer["District"] || "",
          geolocation: farmer["Geolocation"] || "",
          disability: farmer["Disability"] || "",
          preferred_language: farmer["Preferred Language"] || "",
          date_of_birth: farmer["Date Of Birth"] || "",
          educational_level: farmer["Education Level"] || "",
          other_occupation: farmer["other occupation"] || "",
          region: farmer["Region"] || "",
          town: farmer["Town"] || "",
        },
        farm: {
          farmSize: farmer["Total Farm Size (Acres)"] || 0,
          numberOfCrop: farmer["No. of Crops under cultivation"] || 0,
          cropOne: farmer["Crop 1"] || "",
          cropOneSize: farmer["Crop 1 Size (Acres)"] || 0,
          cropTwo: farmer["Crop 2"] || "",
          cropTwoSize: farmer["Crop 2 Size (Acres)"] || 0,
          cropThree: farmer["Crop 3"] || "",
          cropThreeSize: farmer["Crop 3 Size (Acres)"] || 0,
        },
      };
    });
    return mappedFarmers;
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    convertDataKeys().forEach(async (farmerData) => {
      try {
        const fromData = new FormData();
        Object.keys(farmerData.farmer).forEach((farmerDataKey) => {
          fromData.append(farmerDataKey, farmerData.farmer[farmerDataKey]);
        });

        const { success, message, data } = await postFarmers(fromData);
        setDialogInfo({
          title: "Success",
          text: message,
          icon: "success",
          confirmButtonText: "Close",
        });
        console.log("data returned", data.id);
        const farmerId = data.id;

        const { data: result } = await getCrops();
        // check first crop and get the details
        const crop = result.find(
          (crop) =>
            crop.name.toLowerCase() === farmerData.farm.cropOne.toLowerCase()
        );
        // check the third group and validate the details
        const crop2 = result.find(
          (crop) =>
            crop.name.toLowerCase() === farmerData.farm.cropTwo.toLowerCase()
        );

        // assign teh crops to the farm
        let formattedFarmCrops = {};
        if (
          success &&
          farmerId.length > 0 &&
          farmerData.farm?.farmSize &&
          crop !== undefined
        ) {
          formattedFarmCrops[crop.id] = {
            cropId: crop.id,
            cropCapacity: farmerData.farm.farmSize.toString(),
          };
          // if and only if there is another crop 2 then add it to the list
          if (crop2 !== undefined) {
            formattedFarmCrops[crop2.id] = {
              cropId: crop2.id,
              cropCapacity: farmerData.farm.farmSize.toString(),
            };
          }
          // todo: third crop is unaccountable for not all farms have third crop so look for away to resolve this issue
          const data = {
            farmer_id: farmerId,
            gps_location: JSON.stringify(farmerData.farmer.geolocation) || "",
            town: farmerData.farmer.town || "",
            district: farmerData.farmer.district || "",
            region: farmerData.farmer.region || "",
            crops: formattedFarmCrops,
          };

          const { success, message } = await postFarms(data);
          //alert(message);
          setDialogInfo({
            title: "Success",
            text: message,
            icon: "success",
            confirmButtonText: "Close",
          });
        }

        setIsLoading(false);

        // const
        if (!success) {
          setDialogInfo({
            title: "Failed",
            text: message,
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        console.log("farmer", farmerData);
      } catch (error) {
        setDialogInfo({
          title: "Failed",
          text: error,
          icon: "error",
          confirmButtonText: "Close",
        });
      }
    });
  };

  return (
    <div>
      {dialogInfo && (
        <SweetAlert
          title={dialogInfo.title}
          text={dialogInfo.text}
          icon={dialogInfo.icon}
          confirmButtonText={dialogInfo.confirmButtonText}
        />
      )}
      <form onSubmit={handleUpload}>
        <input type="file" onChange={handleFileChange} />
        <div className="flex  mt-6">
          {loading && <Spinner name="line-spin-fade-loader" />}
          <button
            className="w-28 h-10 bg-black rounded-md text-white"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
      <h2 className="pt-4">Farmers Data loaded</h2>
      <pre>{JSON.stringify(convertDataKeys(), null, 2)}</pre>
    </div>
  );
};

export default UploadBulkFarmers;
