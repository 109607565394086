import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { getCrop } from '../../services/api/crops';
import PracticesList from "../Practices/PracticesList";
import NewCropPractice from "../NewCropPractice";
import Loading from "../shared/Loading";

export default function CropDetails() {
  const { cropId } = useParams();
  const [crop, setCrop] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function refreshCrop() {
      setIsLoading(true);
      const { data: result } = await getCrop(cropId)
      setCrop(result)
      setIsLoading(false);
    }
    refreshCrop()
  }, [cropId])

  return (
    <>
      <div>
        <Sidebar selected="Crops" />
        <div>
          <Topbar />
          <main className="md:pl-64 flex flex-col flex-1 px-4 sm:px-6">
            <div className="py-6">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">
                  {crop.name}
                </h1>
              </div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {isLoading ? (
                  <Loading />
                ) : (
                  <div className="py-4">
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                          <div className="sm:col-span-3">
                            <dt className="text-sm font-medium text-gray-500">
                              Thumbnail
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {crop?.thumbnail && (
                                <a
                                  href={crop.thumbnail}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="underline font-semibold text-gray-800"
                                >
                                  View file
                                </a>
                              )}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Created At
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {dayjs(crop.updatedAt).format("YYYY-MM-DD HH:mm")}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Last Updated At
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {dayjs(crop.updatedAt).format("YYYY-MM-DD HH:mm")}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Deleted At
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {dayjs(crop.deletedAt).format("YYYY-MM-DD HH:mm")}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-8">
                <main className="flex flex-col flex-1">
                  <div className="sm:flex sm:items-center mb-2">
                    <div className="sm:flex-auto ">
                      <h1 className="text-2xl font-semibold text-gray-900">
                        Best Practices
                      </h1>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                      <NewCropPractice crop={crop} />
                    </div>
                  </div>
                  <PracticesList
                    defaultPractices={crop?.practices}
                    showNewButton={false}
                  />
                </main>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}