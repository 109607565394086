import Spinner from "react-spinkit";

export default function Loading() {
  return (
    <div className="my-24 text-center text-gray-400 text-2xl">
      <div className="flex justify-center items-center">
        <Spinner name="line-spin-fade-loader" />
      </div>
    </div>
  );
}
