import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import NewAgent from "./NewAgent";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { getAgents } from "../services/api/agents";
import Pagination from "./shared/Pagination";
import Loading from "./shared/Loading";
import EmptyState from "./shared/EmptyState";

export default function Agents() {
  const [agents, setAgents] = useState([])
  const [meta, setMeta] = useState({})
  const [isLoading, setIsLoading] = useState(false);

  async function refreshAgents(page = 1) {
    setIsLoading(true);
    const { data: result, meta: resultsMeta } = await getAgents({
      params: {
        page,
      }
    })
    setAgents(result)
    setMeta(resultsMeta)
    setIsLoading(false);
  }

  useEffect(() => {
    refreshAgents()
  }, [])

  async function searchForAgents(search) {
    setIsLoading(true);
    const { data: result } = await getAgents({
      params: {
        q:  search
      }
    })
    setAgents(result)
    setIsLoading(false);
  }
  
  return (
    <>
      <div>
        <Sidebar selected="Agents" />
        <div>
          <Topbar onSearchUpdated={(s) => searchForAgents(s)} />
          <main className="md:pl-64 flex flex-col flex-1 px-4 sm:px-6">
            <div className="pt-4 ml-4 sm:flex sm:items-center">
              <div className="sm:flex-auto ">
                <h1 className="text-2xl font-semibold text-gray-900">Agents</h1>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <NewAgent />
              </div>
            </div>
            <div className="px-4 mt-8 flex flex-col">
              {isLoading ? (
                <Loading />
              ) : !agents.length ? (
                <div className="my-12 mb-24">
                  <EmptyState title="Agents" showButton={false} />
                </div>
              ) : (
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Phone Number
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Added On
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Last Updated At
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Actions</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {agents.map((agent) => (
                            <tr key={agent.id}>
                              <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                                <a href={`/agents/${agent.id}`}>{agent.name}</a>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {agent.phoneNumber}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {dayjs(agent.createdAt).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {dayjs(agent.updatedAt).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </td>

                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <a
                                  href={`/agents/${agent.id}`}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  View
                                  <span className="sr-only">
                                    , {agent.name}
                                  </span>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <Pagination
                        meta={meta}
                        onPrev={(page) => refreshAgents(page)}
                        onNext={(page) => refreshAgents(page)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
