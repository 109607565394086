import { API_BASE_URL } from '../constants'
import { get, patch, post, destroy } from './requests'

export const getCrops = async (options = {}) => {
  return await get(`${API_BASE_URL}/crops`, options)
}

export const getCrop = async (cropId, options = {}) => {
  return await get(`${API_BASE_URL}/crops/${cropId}`, options)
}

export const postCrops = async (body, options = {}) => {
  return await post(`${API_BASE_URL}/crops`, body, options)
}

export const patchCrop = async (cropId, body, options = {}) => {
  return await patch(`${API_BASE_URL}/crops/${cropId}`, body, options)
}

export const deleteCrop = async (cropId, options = {}) => {
  return await destroy(`${API_BASE_URL}/crops/${cropId}`, options)
}

export const postCropPractices = async (cropId, body, options = {}) => {
  return await post(`${API_BASE_URL}/crops/${cropId}/practices`, body, options)
}
